function MessageBox({ type, text, position, date, dateString }) {
    return (
        <>
            <div className="chat-con" style={position == "left" ? { justifyContent: "flex-start" } : { justifyContent: "flex-end" }}>
                <div className="chat-message-wrapper" style={position == "left" ? { backgroundColor: "#FDFFE2", transformOrigin: "left center" } : { transformOrigin: "right center" }}>
                    {text}
                </div>
            </div>
        </>
    )
}

export default MessageBox;
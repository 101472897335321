import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Static/CSS/login.css';

function Signup({ setVerificationPageFlag, setSignupUserData }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    setLoading(true);
    
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + '/user/verify', {
        name,
        email
      },
        { withCredentials: true });

      if (response.status === 200) {
        setMessage(response.data.message || 'Verification code sent.');
        setVerificationPageFlag(true);
        setSignupUserData({ name: name, email: email, password: password });
        navigate("/verification");
      }
      else {
        setMessage(response.data.message || 'Unable to send the code.');
      }
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      } else if (error.request) {
        setMessage('No response from the server');
      } else {
        setMessage('Error creating the request');
      }
    }
    setLoading(false);
  };

  return (
    <div className="signup-container">
      <form onSubmit={handleSubmit} className="signup-form">
        <h2>Sign Up</h2>
        <div className="input-group">
          {/* <label htmlFor="name">Name:</label> */}
          <input
            type="text"
            id="name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
        </div>
        <div className="input-group">
          {/* <label htmlFor="email">Email:</label> */}
          <input
            type="email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>
        <div className="input-group">
          {/* <label htmlFor="password">Password:</label> */}
          <input
            type="password"
            id="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>
        {message && <div className="backend-message">{message}</div>}
        <button type="submit" disabled={loading ? true : false}>
          {loading ?
            <span className='material-symbols-outlined loading-icon'>progress_activity</span>
            : "Sign Up"}
        </button>
        <p className="login-link">
          Already have an account? <Link to="/login">Log in</Link>
        </p>
      </form>
    </div>
  );
}

export default Signup;

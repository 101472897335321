import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import MessageBox from './MessageBox';
import '../Static/CSS/chat.css';
import '../Static/CSS/chatcode.css';

function Chat({ userData }) {

    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [htmlContent, setHtmlContent] = useState("Preview");
    const [codeOpen, setCodeOpen] = useState(false);

    setTimeout(() => Prism.highlightAll(), 0);


    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    };

    const callAI = async (userInput) => {
        const previewLoading = document.getElementsByClassName("preview-loading")[0];
        const typingIndicator = document.getElementsByClassName("typing-indicator")[0];

        setTimeout((e) => { typingIndicator.style.display = "flex" }, 500);

        let res = await axios.post(process.env.REACT_APP_BACKEND_URL + '/gpt/ask', {
            userInput: userInput,
            userId: userData.id
        },
            { withCredentials: true });
        const newMessage = {
            position: 'left',
            type: 'text',
            text: res.data.text,
            date: new Date(),
        };
        typingIndicator.style.display = "none";
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        if (res.data.previewFlag) {
            previewLoading.style.display = "flex";
            let htmlRes = await axios.get(process.env.REACT_APP_BACKEND_URL + '/preview/desktop', { withCredentials: true });
            setHtmlContent(htmlRes.data.data);
            previewLoading.style.display = "none";
        }
    }

    const handleSendMessage = () => {
        const welcomeChatMsg = document.getElementsByClassName("welcome-chat-msg")[0];
        welcomeChatMsg.style.display = "none";

        if (userInput.trim()) {
            const newMessage = {
                position: 'right',
                type: 'text',
                text: userInput,
                date: new Date(),
            };
            setMessages([...messages, newMessage]);
            setUserInput('');

            callAI(userInput);
        }
    };

    const handleEnter = (e) => {
        if(e.which == 13){
            handleSendMessage();
        }
    }

    const handleCodeClick = () => {
        const codeWrapper = document.getElementsByClassName("code-wrapper")[0];
        let screenWidth = window.screen.width;

        // Change the code preview layout for mobile/tablet devices
        if(screenWidth <= 1000){
            if(codeOpen){
                codeWrapper.style.transform = "translateX(100%)";
            }
            else{
                codeWrapper.style.transform = "translateX(0)";
            }
        }
        else{
            if(!codeOpen){
                codeWrapper.style.transform = "translateX(0)";
            }
            else{
                codeWrapper.style.transform = "translateX(100%)";
            }
        }
        setCodeOpen(!codeOpen);
    }

    return (
        <div className="chat-page-container">
            <div className="code-wrapper">
                <div className="code-container">
                    <div className="code-logo" onClick={handleCodeClick}>
                        <span class="material-symbols-outlined code-logo-span">
                            {codeOpen ? "close" : "code"}
                        </span>
                    </div>
                    <div className="code-preview">
                        <div className="Code">
                        <pre className='line-numbers'>
                            <code className='language-html'>{htmlContent}</code>
                        </pre>
                        </div>
                    </div>
                </div>
            </div>

            <div className="user-chat-container">
                <div className="user-chat">
                    <div className="welcome-chat-msg">
                        <p className="welcome-q">
                            Have a design but don't how to code?
                        </p>
                        <p className="welcome-q-1">
                            Not to worry
                        </p>
                        <p className="welcome-q-2">
                            Ask <span className='chat-web-title'>{"</codesip>"}</span>
                        </p>
                    </div>

                    {messages.map((message, index) => (
                        <MessageBox
                            key={index}
                            position={message.position}
                            type={message.type}
                            text={message.text}
                            date={message.date}
                            dateString={message.date.toLocaleTimeString()}
                        />
                    ))}
                    <div className="chat-con" style={{ justifyContent: "flex-start" }}>
                        <div className="chat-message-wrapper typing-indicator" style={{ backgroundColor: "#FDFFE2", transformOrigin: "left" }}>
                            {/* GPT typing indicator */}
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                        </div>
                    </div>
                </div>
                <div className="message-box-container">
                    <div className="message-box">
                        <input
                            type="text"
                            name="user-input"
                            id="user-input"
                            placeholder="Write your imagination here"
                            value={userInput}
                            onChange={handleInputChange}
                            onKeyDown={handleEnter}
                        />
                        <button type="button" className="build-button" onClick={handleSendMessage}>
                            <span class="material-symbols-outlined build-button-span">
                                arrow_upward
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="website-preview-container">
                <div className="website-frame-wrapper">
                    <iframe className="website-frame" srcDoc={
                        htmlContent == "Preview" ?
                            "<div style='width:100vw; height: 100vh; display:flex; justify-content:center; align-items:center; font-style:Calibri;'>Live Preview of your website will appear here<div>"
                            : htmlContent}>
                    </iframe>
                    <div className="preview-loading">Loading the preview....</div>
                </div>
            </div>
        </div>
    );
}

export default Chat;
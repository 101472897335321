import axios from "axios";
import { useEffect } from "react";
import "../Static/CSS/home.css";
import welcomeImage from "../Static/Images/welcome.png";

function Home() {

    return(
        <>
        <section className="home-container">
            <section className="home1 container">
                    <div className="home1-left">
                        <h1 className="welcome-msg">
                            GET <span className="welcome-msg-code">CODE</span> & <span className="welcome-msg-preview">PREVIEW</span> AS YOU IMAGINE
                        </h1>
                    </div>
                    <div className="home1-right">
                        <img src={welcomeImage} alt="image" className="welcome-image"/>
                    </div>
            </section>
        </section>
        </>
    );
}

export default Home;
